import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./src/styles/theme";
import GlobalStyle from "./src/styles/globalStyle";

// load font awesome css for icons in css
import "@fortawesome/fontawesome-pro/css/light.css";

// Create portal in onClientEntry instead of at module level
export const onClientEntry = () => {
  const portal = document.createElement("div");
  portal.id = "portal-root";
  document.body.appendChild(portal);
};

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle theme={theme} />
    {element}
  </ThemeProvider>
);
